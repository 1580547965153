import React from 'react';
import { Outlet} from 'react-router-dom';


const M1 = () => {
    


    ///请求 返回json  写入

    return <>
     
        
        <Outlet></Outlet>
 

     


    </>
}

export default M1;