
import React from 'react'
import { List, Footer,   Ellipsis,  Tag } from 'antd-mobile'





const Y1menu = () => {
    const token = JSON.parse(localStorage.getItem('cx315_token'))


 
    return <>
        {/* 追溯默认页面，显示基本信息，若有活动=》渲染，若有找回信息=》渲染, */}
       



        <List style={{ '--font-size': '1em' }}>
            <List.Item extra={token.resq.dbcode} > 单元识别码</List.Item>
          

            <List.Item description={<Ellipsis
                direction='end'
                 content={`http://ddspp.cn/G/${token.resq.dbcode}`}
                expandText='展开'
                collapseText='收起'
            />} >
                追溯网址
            </List.Item>
            <List.Item extra={<Tag
                color='success'
                fill='outline'
                style={{ '--background-color': '#c8f7c5', '--border-radius': '3px' }}
            >
               
            </Tag>}>扫描次数</List.Item>



            <List.Item extra={token.pname} > 产品名称</List.Item>
            <List.Item extra={token.gms} > 规格</List.Item>


        </List>

        
       




        <Footer
            label='没有更多了'
            content='@ 2024-2026 ddspp.cn All rights reserved'

        ></Footer>

        <Footer label='-'></Footer>

    </>
}

export default Y1menu;