

import S1 from "@/pages/S1";
import G1 from "@/pages/G1";
import M1 from "@/pages/M1";

import E404 from "@/pages/E404";
import Y1menu from "@/pages/Y1menu";

import Surl from "@/pages/Surl";
import Gurl from "@/pages/Gurl";
import { createBrowserRouter } from 'react-router-dom';


const router = createBrowserRouter([
    {path: "/",
        element: <M1></M1>,}
    ,
    {
        path: "S",
        element: <S1></S1>,
        children: [
        {
            path: "index",
            element: <Y1menu />,
        },
        {
            path: "*",
            element: <E404 />
        }

        ],
    },
    {
        path: "G",
        element: <G1></G1>,
        children: [
        {
            path: "index",
            element: <Y1menu />,
        },
        {
            path: "*",
            element: <E404 />
        }

        ],
    },
     {
         path: "/G/:dbcode",
         element: <Gurl />
     },
     {
        path: "/S/:dbcode",
        element: <Surl />
    },
])
export default router