import React from 'react'
import { TabBar } from 'antd-mobile'
import {
    TravelOutline,
    TruckOutline,
    FillinOutline,
    InformationCircleOutline,
} from 'antd-mobile-icons'

import {
    Outlet,
    useLocation,
    useNavigate
} from 'react-router-dom'
import './index.scss';

const G1 = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location


    const setRouteActive = (value) => {
        navigate('/G'+value)

    }

    const tabs = [
        {
            key: '/index',
            title: '首页',
            icon:<FillinOutline />,
        },
        {
            key: '/source',
            title: '溯源',
            icon: <TravelOutline />,  //<FaceRecognitionOutline />
        },
        {
            key: '/cr_log',
            title: '流转记录',
            icon: <TruckOutline />,
        },
        {
            key: '/about',
            title: '企业简介',
            icon: <InformationCircleOutline />,
        },

    ]

    return (
       <>
       <Outlet></Outlet>

<TabBar activeKey={pathname} onChange={value => setRouteActive(value)} className='foot'
// style={{ 'background': 'rgb(22,119,255)' }}
>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
       </>


   
        

    )
}

export default G1;